import { render, staticRenderFns } from "./LogBalancesDealer.vue?vue&type=template&id=6f70e904&scoped=true&"
import script from "./LogBalancesDealer.vue?vue&type=script&lang=js&"
export * from "./LogBalancesDealer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f70e904",
  null
  
)

export default component.exports